import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LoadingIndicator, Button } from 'ui-core-ssgr';
import { ContainerBox, CopyNumberCaption } from './styles';
import {
  LoadingWrapper,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import DropDownControl from '../ModalControls/DropDownControl/index';
import InputControl from '../ModalControls/InputControl';
import { 
  ButtonGroupBottom,
  ModalButton, 
  ReadOnlyTextTitle, 
  ReadOnlyTextArea  
} from '../../../shared/styles/styledComponents';

class TaxIdArrangementRuleModal extends Component {
  state = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    copyNumber: '',
    taxIdNumber: '',
    deliveryMethod: 'default',
    deliveryAddress: 'default',
    checkForm: 'default',
    eobForm: 'default',
    abfForm: 'default',
    isButtonDisabled: true,
    copyNumberError: false,
    copyNumberErrorMessage: '',
    taxIdNumberError: false,
    taxIdNumberErrorMessage: '',
    deliveryAddressError: false,
    deliveryAddressErrorMessage: '',
  };

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { detailElements, isShowingTaxIdArrangementRuleModal } = this.props;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingTaxIdArrangementRuleModal !==
        isShowingTaxIdArrangementRuleModal
    ) {
      this.getInitialValues();
    }
  }

  populateCodeFromValue = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name && detailElements.claimTypesList) {
        let selectedCode = '';
        let selectedList = '';
        let firstValue = '';
        let selectedName = name.replace('List', '');
        if (selectedName.indexOf('Types')) {
          selectedName = selectedName.replace('Types', 'Type');
        }
        const selectedValue = selectedRuleDetail[selectedName];
        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'deliveryMethodList') selectedList = 'deliveryMethodList';
        if (name === 'deliveryAddressList')
          selectedList = 'deliveryAddressList';
        if (name === 'checkFormList') selectedList = 'checkFormList';
        if (name === 'eobFormList') selectedList = 'eobFormList';
        if (name === 'abfFormList') selectedList = 'abfFormList';

        if (
          name === 'checkFormList' ||
          name === 'eobFormList' ||
          name === 'abfFormList'
        ) {
          firstValue = detailElements[selectedList].find(x =>
            x.value.includes(selectedValue),
          );
        } else {
          firstValue = detailElements[selectedList].find(x =>
            x.value.includes(selectedValue),
          );
        }

        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        arr.map(r => {
          if (
            name === 'checkFormList' ||
            name === 'eobFormList' ||
            name === 'abfFormList'
          ) {
            if (r.value.includes(selectedValue)) {
              selectedCode = r.code;
            }
          } else if (r.value === selectedValue) {
            selectedCode = r.code;
          }
          return {
            label: r.value.toString(),
            value: r.code.toString(),
          };
        });
        return selectedCode;
      }
    }
  };

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    let copyNumber = '';
    let taxIdNumber = '';
    let deliveryMethod = 'default';
    let deliveryAddress = 'default';
    let checkForm = 'default';
    let eobForm = 'default';
    let abfForm = 'default';

    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      actionType !== 'Add a'
    ) {
      if (actionType === 'Edit a' || actionType === 'Clone a') {
        if (detailElements.claimTypesList && selectedRuleDetail.claimType) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
        if (
          detailElements.recipientTypesList &&
          selectedRuleDetail.recipientType
        ) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).code;
        }
        if (
          detailElements.documentTypesList &&
          selectedRuleDetail.documentType !== 'Any'
        ) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).code;
        } else {
          documentType = 'default';
        }
      }

      if (actionType === 'Read only') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        }
        if (
          detailElements.deliveryMethodList &&
          selectedRuleDetail.deliveryMethod
        ) {
          deliveryMethod = detailElements.deliveryMethodList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryMethod),
          ).value;
        }

        if (
          detailElements.deliveryAddressList &&
          selectedRuleDetail.deliveryAddress
        ) {
          deliveryAddress = detailElements.deliveryAddressList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryAddress),
          ).value;
        }

        if (detailElements.checkFormList && selectedRuleDetail.checkForm) {
          checkForm = detailElements.checkFormList.find(o =>
            o.value.includes(selectedRuleDetail.checkForm),
          ).value;
        }

        if (detailElements.eobFormList && selectedRuleDetail.eobForm) {
          eobForm = detailElements.eobFormList.find(o =>
            o.value.includes(selectedRuleDetail.eobForm),
          ).value;
        }
        if (detailElements.abfFormList && selectedRuleDetail.abfForm) {
          abfForm = detailElements.abfFormList.find(o =>
            o.value.includes(selectedRuleDetail.abfForm),
          ).value;
        }

      } else {

        if (
          detailElements.deliveryMethodList &&
          selectedRuleDetail.deliveryMethod
        ) {
          deliveryMethod = detailElements.deliveryMethodList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryMethod),
          ).code;
        }
        if (
          detailElements.deliveryAddressList &&
          selectedRuleDetail.deliveryAddress
        ) {
          deliveryAddress = detailElements.deliveryAddressList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryAddress),
          ).code;
        }
        if (detailElements.checkFormList && selectedRuleDetail.checkForm) {
          checkForm = detailElements.checkFormList.find(o =>
            o.value.includes(selectedRuleDetail.checkForm),
          ).code;
        }
        if (detailElements.eobFormList && selectedRuleDetail.eobForm) {
          eobForm = detailElements.eobFormList.find(o =>
            o.value.includes(selectedRuleDetail.eobForm),
          ).code;
        }
        if (detailElements.abfFormList && selectedRuleDetail.abfForm) {
          abfForm = detailElements.abfFormList.find(o =>
            o.value.includes(selectedRuleDetail.abfForm),
          ).code;
        }
      }
      if (selectedRuleDetail.taxIdNumber) {
        const { taxIdNumber: ruleDetailTaxIdNumber } = selectedRuleDetail;
        taxIdNumber = ruleDetailTaxIdNumber;
      }
      if (selectedRuleDetail.copyNumber) {
        const { copyNumber: ruleDetailCopyNumber } = selectedRuleDetail;
        copyNumber = ruleDetailCopyNumber;
      }
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          copyNumber,
          taxIdNumber,
          deliveryMethod: deliveryMethod.toString(),
          deliveryAddress: deliveryAddress.toString(),
          checkForm,
          eobForm,
          abfForm,
          isButtonDisabled: true,
        },
        () => {
          // to validate existing value from db
          this.validateFormInputs('taxIdNumber');
          this.validateFormInputs('copyNumber');
          this.validateFormInputs('deliveryAddress');
        },
      );
    } else {
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          copyNumber,
          taxIdNumber,
          deliveryMethod,
          deliveryAddress,
          checkForm,
          eobForm,
          abfForm,
          isButtonDisabled: true,
        },
        () => {
          // to validate existing value from db
          this.validateFormInputs('taxIdNumber');
          this.validateFormInputs('copyNumber');
          this.validateFormInputs('deliveryAddress');
        },
      );
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (detailElements) {
        let options = [];
        let selectedList = '';
        let selectedName = name.replace('List', '');
        if (selectedName.indexOf('Types')) {
          selectedName = selectedName.replace('Types', 'Type');
        }
        const selectedValue = selectedRuleDetail[selectedName];

        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'deliveryMethodList') selectedList = 'deliveryMethodList';
        if (name === 'deliveryAddressList')
          selectedList = 'deliveryAddressList';
        if (name === 'checkFormList') selectedList = 'checkFormList';
        if (name === 'eobFormList') selectedList = 'eobFormList';
        if (name === 'abfFormList') selectedList = 'abfFormList';

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        options = arr
          .map(r => ({
            label: r.value.toString(),
            value: r.code.toString(),
          }))
          .filter(v => v.value !== '');
        return options;
      }
    }
  };

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingTaxIdArrangementRuleModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsShowingTaxIdArrangementRuleModal(false);
    setIsReadOnlyRuleDetail(false);
    this.setState({...this.state});
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      selectedRecord,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      abfForm,
      copyNumber,
      taxIdNumber,
    } = this.state;

    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = record.id;

    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      abfForm,
      copyNumber,
      taxIdNumber,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  setButtonDisableOnDefault(dbCodes) {
    const { actionType } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      abfForm,
      copyNumber,
      taxIdNumber,
    } = this.state;
    let newState = false;
    if (
      (actionType === 'Edit a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode &&
          deliveryMethod === dbCodes.deliveryMethodDbCode &&
          deliveryAddress === dbCodes.deliveryAddressDbCode &&
          checkForm === dbCodes.checkFormDbCode &&
          eobForm === dbCodes.eobFormDbCode &&
          abfForm === dbCodes.abfFormDbCode &&
          copyNumber === dbCodes.copyNumberDbCode &&
          taxIdNumber === dbCodes.taxIdNumberDbCode)) ||
      (actionType === 'Clone a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode &&
          copyNumber === dbCodes.copyNumberDbCode &&
          taxIdNumber === dbCodes.taxIdNumberDbCode))
    ) {
      newState = true;
    }
    this.setState({ isButtonDisabled: newState });
  }

  checkUnsavedChanges = event => {
    const { selectedRuleDetail } = this.props;
    const { populateCodeFromValue, validateFormInputs } = this;
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const deliveryMethodDbCode = populateCodeFromValue('deliveryMethodList');
    const deliveryAddressDbCode = populateCodeFromValue('deliveryAddressList');
    const checkFormDbCode = populateCodeFromValue('checkFormList');
    const eobFormDbCode = populateCodeFromValue('eobFormList');
    const abfFormDbCode = populateCodeFromValue('abfFormList');
    const taxIdNumberDbCode = selectedRuleDetail.taxIdNumber;
    const copyNumberDbCode = selectedRuleDetail.copyNumber;

    const dbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      deliveryMethodDbCode,
      deliveryAddressDbCode,
      checkFormDbCode,
      eobFormDbCode,
      abfFormDbCode,
      taxIdNumberDbCode,
      copyNumberDbCode,
    };
    dbCodes.deliveryMethodDbCode = dbCodes.deliveryMethodDbCode === '' ? 'default' : dbCodes.deliveryMethodDbCode;
    dbCodes.checkFormDbCod = dbCodes.checkFormDbCod === '' ? 'default' : dbCodes.checkFormDbCod;
    dbCodes.eobFormDbCode = dbCodes.eobFormDbCode === '' ? 'default' : dbCodes.eobFormDbCode;
    dbCodes.abfFormDbCode =  dbCodes.abfFormDbCode === '' ? 'default' : dbCodes.abfFormDbCode;
 
    if (event.target) {
      const { name, value } = event.target;
      this.setState(
        {
          [name]: value,
          dbCodes,
        },
        () => {
          validateFormInputs(name);
          this.setButtonDisableOnDefault(dbCodes);
        },
      );
    }
  };

  validateFormInputs = inputField => {
    const { taxIdNumber, copyNumber, deliveryAddress } = this.state;
    if (inputField === 'copyNumber') {
      let copyNumberError = false;
      let copyNumberErrorMessage = '';
      const regExpDecimal = /^(\d?[1-9]|c[1-9]|[1-9]0)$/gi;
      copyNumberError =
        copyNumber !== undefined
          ? !regExpDecimal.test(copyNumber)
          : copyNumberError;
      copyNumberErrorMessage = copyNumberError ? 
        'Copy Number is required. Must be 2 digit value from range 01 – 99 OR C1 – C9.' : '';
      this.setState({
        copyNumberError,
        copyNumberErrorMessage,
      });
    }

    if (inputField === 'taxIdNumber') {
      let taxIdNumberError = false;
      let taxIdNumberErrorMessage = '';
      const regExptaxIdNumber = /^\s*(\S\s*){1,30}$/;
      taxIdNumberError =
        taxIdNumber !== undefined
          ? !regExptaxIdNumber.test(taxIdNumber)
          : taxIdNumberError;
      taxIdNumberErrorMessage = taxIdNumberError ? 
        'Tax ID is required. Max allowed characters is 30' : '';
      this.setState({
        taxIdNumberError,
        taxIdNumberErrorMessage,
      });
    }
    if (inputField === 'deliveryAddress') {
      let deliveryAddressError = false;
      let deliveryAddressErrorMessage = '';
      if (deliveryAddress === 'default' || deliveryAddress === undefined) {
        deliveryAddressError = true;
      }
      deliveryAddressErrorMessage = deliveryAddressError ? 'You must select a delivery address' : '';
      this.setState({
        deliveryAddressError,
        deliveryAddressErrorMessage,
      });
    }
  };

  handleChangeFormat = input => {
    const inputValue = input.target.value;
    let formatValue = inputValue.replace(/^(\d)$/, '0$1');
    const regExpAlphaNumeric = /^[a-z0-9]+$/i;
    if (regExpAlphaNumeric.test(formatValue))
      formatValue = formatValue.replace(/(\b[a-z](?!\s))/g, (
        formatValue,
      ) => formatValue.toUpperCase());
    this.setState({
      copyNumber: formatValue,
    });
  };

  handleEnterPressed = event => {
    const { handleChangeFormat } = this;
    if (event.key === 'Enter') {
      handleChangeFormat(event);
    }
  };

  render() {
    const {
      checkUnsavedChanges,
      handleChangeFormat,
      handleEnterPressed,
    } = this;
    const {
      claimType,
      recipientType,
      documentType,
      copyNumber,
      taxIdNumber,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      abfForm,
      isButtonDisabled,
      copyNumberError,
      taxIdNumberError,
      copyNumberErrorMessage,
      taxIdNumberErrorMessage,
      deliveryAddressError,
      deliveryAddressErrorMessage,
    } = this.state;
    const { loading, selectedRuleRecord, selectedRecord, actionType } = this.props;
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;
    const claimTypeOptions = this.populateDataDropDown('claimTypesList');
    const recipientTypeOptions = this.populateDataDropDown(
      'recipientTypesList',
    );
    const documentTypeOptions = this.populateDataDropDown('documentTypesList');
    const deliveryMethodListOptions = this.populateDataDropDown(
      'deliveryMethodList',
    );
    const deliveryAddressListOptions = this.populateDataDropDown(
      'deliveryAddressList',
    );
    const checkFormListOptions = this.populateDataDropDown('checkFormList');
    const eobFormListOptions = this.populateDataDropDown('eobFormList');
    const abfFormListOptions = this.populateDataDropDown('abfFormList');

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    let copyNumberReadOnly;
    let taxIdNumberReadOnly;
    let deliveryMethodReadOnly;
    let deliveryAddressReadOnly;
    let checkFormReadOnly;
    let eobFormReadOnly;
    let abfFormReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
      copyNumberReadOnly = (copyNumber !== '') ? copyNumber : '-';
      taxIdNumberReadOnly = (taxIdNumber !== '') ? taxIdNumber : '-';
      deliveryMethodReadOnly = (deliveryMethod !== 'default') ? deliveryMethod : 'Originally Specified Delivery Type';
      deliveryAddressReadOnly = (deliveryAddress !== 'default') ? deliveryAddress : '-';
      checkFormReadOnly = (checkForm !== 'default') ? checkForm : '-';
      eobFormReadOnly = (eobForm !== 'default') ? eobForm : '-';
      abfFormReadOnly = (abfForm !== 'default') ? abfForm : '-';
    }

    if (deliveryAddressListOptions !== undefined) {
      const selectOption = { label: 'Select', value: 'default' };
      deliveryAddressListOptions.unshift(selectOption);
    }

    return (
      <>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-claim-type"
              dropDownLabel="claim type"
              dropDownName="claimType"
              dropDownOptions={claimTypeOptions}
              dropDownInitialValue={claimType}
              dropDownOnChangeHandle={checkUnsavedChanges}
            /> ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-recipient-type"
              dropDownLabel="recipient"
              dropDownName="recipientType"
              dropDownOptions={recipientTypeOptions}
              dropDownInitialValue={recipientType}
              dropDownOnChangeHandle={checkUnsavedChanges}
            /> ) : <ReadOnlyTextTitle>Recipient Type<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-document-type"
              dropDownLabel="document type"
              dropDownName="documentType"
              dropDownOptions={documentTypeOptions}
              dropDownInitialValue={documentType}
              dropDownOnChangeHandle={checkUnsavedChanges}
            />) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox className="tax-id-arr-copy-number-wrap">
          {actionType !== 'Read only' ? (
            <InputControl
              inputDomID="tax-id-arr-copy-number"
              inputLabel="copy number"
              inputName="copyNumber"
              inputInitialValue={copyNumber}
              inputStartWithBlankValue={false}
              inputOnChangeHandle={checkUnsavedChanges}
              inputDisabled={false}
              inputIsHovered={false}
              inputErrorMessage={copyNumberErrorMessage}
              inputHasError={copyNumberError}
              inputStyles={{ width: '100px' }}
              inputOnBlurHandle={handleChangeFormat}
              inputOnKeyDown={handleEnterPressed}
            />) : <ReadOnlyTextTitle>Copy Number<ReadOnlyTextArea>{copyNumberReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CopyNumberCaption>
            (99 all copies, C1-C9 generate additional copies)
            </CopyNumberCaption>) : null}
        </ContainerBox>
        <ContainerBox className="tax-id-arr-tax-id-number-wrap">
          {actionType !== 'Read only' ? (
            <InputControl
              inputDomID="tax-id-arr-tax-id-number"
              inputLabel="tax id number"
              inputName="taxIdNumber"
              inputInitialValue={taxIdNumber}
              inputStartWithBlankValue={false}
              inputOnChangeHandle={checkUnsavedChanges}
              inputDisabled={false}
              inputIsHovered={false}
              inputErrorMessage={taxIdNumberErrorMessage}
              inputHasError={taxIdNumberError}
              inputOnBlurHandle={() => null}
              inputOnKeyDown={() => null}
            />) : <ReadOnlyTextTitle>Tax Id Number<ReadOnlyTextArea>{taxIdNumberReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-delivery-method"
              dropDownLabel="delivery method"
              dropDownName="deliveryMethod"
              dropDownOptions={deliveryMethodListOptions}
              dropDownInitialValue={deliveryMethod}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px', paddingTop: '8px' }}
            />) : <ReadOnlyTextTitle>Delivery Method<ReadOnlyTextArea>{deliveryMethodReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>} 
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-delivery-address"
              dropDownLabel="delivery address"
              dropDownName="deliveryAddress"
              dropDownOptions={deliveryAddressListOptions}
              dropDownInitialValue={deliveryAddress}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
              dropDownHasError={deliveryAddressError}
              dropDownErrorMessage={deliveryAddressErrorMessage}
            />) : <ReadOnlyTextTitle>Delivery Address<ReadOnlyTextArea>{deliveryAddressReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>} 
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-alternative-check-form"
              dropDownLabel="alternative check form"
              dropDownName="checkForm"
              dropDownOptions={checkFormListOptions}
              dropDownInitialValue={checkForm}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
            />) : <ReadOnlyTextTitle>Check Form<ReadOnlyTextArea>{checkFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>} 
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-alternative-eob-form"
              dropDownLabel="alternative eob form"
              dropDownName="eobForm"
              dropDownOptions={eobFormListOptions}
              dropDownInitialValue={eobForm}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
            />) : <ReadOnlyTextTitle>Eob Form<ReadOnlyTextArea>{eobFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>} 
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="tax-id-arr-abf-form"
              dropDownLabel="abf form"
              dropDownName="abfForm"
              dropDownOptions={abfFormListOptions}
              dropDownInitialValue={abfForm}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
            />) : <ReadOnlyTextTitle>Abf Form<ReadOnlyTextArea>{abfFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>} 
        </ContainerBox>

        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={
                  isButtonDisabled ||
                  loading.has(recordId) ||
                  taxIdNumberError ||
                  copyNumberError ||
                  deliveryAddressError
                }
              />) : null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

TaxIdArrangementRuleModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  isShowingTaxIdArrangementRuleModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  actionType: PropTypes.string,
  selectedRuleTypeId: PropTypes.string,
  selectedRecord: PropTypes.object,
};
export default TaxIdArrangementRuleModal;
